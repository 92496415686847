<template>
  <a-spin :spinning="loading" style="height: 100%">
    <a-card :bordered="false" class="content">
      <div class="box">
        <h1>欢迎使用外呼系统</h1>
        <p>
          当前状态：<span>{{ userStatus | userStatus }}</span>
        </p>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'UserHome',
  data() {
    return {}
  },
  filters: {
    userStatus(value) {
      console.log(value)
      switch (value) {
        case 'LINE':
        case 'IDLE':
          return '等待接听'
        case 'BUSY':
          return '示忙'
        case 'OFF_LINE':
          return '离线'
        default:
          return '示忙'
      }
    }
  },
  computed: {
    ...mapGetters(['userStatus', 'loading'])
  }
}
</script>

<style scoped>
.content {
  height: calc(100% - 43px);
  position: relative;
}
.box {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.box h1 {
  font-size: 40px;
  text-align: center;
}
.box p {
  font-size: 30px;
}
/deep/.ant-spin-container {
  height: 100%;
}
</style>
