<template>
  <complate :is="templateName"></complate>
</template>

<script>
import { mapGetters } from 'vuex'
import adminHome from '@/views/home/adminHome'
import userHome from '@/views/home/userHome'
export default {
  name: 'Home',
  components: {
    adminHome,
    userHome
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      templateName: 'adminHome'
    }
  },
  mounted() {
    console.log(this.userInfo.managerRoles[0].name)
    if (this.userInfo.managerRoles[0].name.includes('admin')) {
      this.templateName = 'adminHome'
    } else {
      this.templateName = 'userHome'
    }
  }
}
</script>

<style scoped></style>
